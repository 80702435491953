import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  list?: string[]
  title?: string
}

export const Equipment = memo(function Equipment({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <FadeInUp>
        <List row wrap>
          {list.map((item, index) => (
            <Item dial={4} key={index} row>
              {
                // @ts-ignore
                item.label
              }
            </Item>
          ))}
        </List>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  margin-top: 3rem;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.25rem;
`

const List = styled(FlexBox)``

const Item = styled(FlexBox)`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-top: 1rem;
  padding-left: 0.875rem;
  padding-right: 3rem;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.2;
    position: absolute;
    top: 0.1875rem;
    left: 0;
    transform: rotate(45deg);
  }
`
