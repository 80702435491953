import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface ProvisionsProps {
  icon?: string
  label?: string
}

export interface Props {
  list?: ProvisionsProps[]
  title?: string
}

export const Provisions = memo(function Provisions({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <FadeInUp>
        <List row wrap>
          {list.map((item, index) => (
            <Item dial={4} key={index} row>
              {item.icon ? (
                <SVG src={item.icon} width="20" height="20" alt={item.label} />
              ) : null}
              {item.label ? <Label>{item.label}</Label> : null}
            </Item>
          ))}
        </List>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  margin-top: 3rem;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.75rem;
`

const List = styled(FlexBox)``

const Item = styled(FlexBox)`
  width: 50%;
  margin-top: 1.25rem;
`

const SVG = styled.img`
  width: auto;
  height: 1.25rem;
  margin-right: 0.75rem;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  text-transform: uppercase;
`
